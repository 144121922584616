/* eslint-disable import/exports-last */
import { APIDCN, apiFetch } from '@api/base';
import type { TAction, TDispatch } from '@models/types';

import { HasilPencarianActionType } from './types';

import type { HasilPencarianAction, HasilPencarianModel } from './types';

const HasilPencarianDefault: HasilPencarianModel = {
  flightTimeLine: {
    alternativeData: [],
    alternativeTotal: 0,
    data: [],
    total: 0
  },
  summaryTimeLimit: []
};

// eslint-disable-next-line import/exports-last
export interface PropsSearchResult {
  airline: string
  departure: string
  departure_date: string
  direct: string
  page: string
  page_size: string
  return: string
  return_date: string
  token: string
}

// eslint-disable-next-line import/exports-last
export interface PropsTermsAndCondition {
  airline: string
  departureDate: string
  inventoryType: string
  price: number
  supplier: string
}

export interface Flight {
  'arrival': string
  'departure': string
  'departure_date': string
}

export interface PropsSearchResultMultiCity {
  'airline': string
  'direct': string
  'flights': Flight[]
  'page': number | string
  'page_size': number | string
  'token': string
}

const HasilPencarianReducer = (state: HasilPencarianModel = HasilPencarianDefault, action: HasilPencarianAction): HasilPencarianModel => {
  switch (action.type) {
    case HasilPencarianActionType.GetFlightTimeLineList:
      return { ...state, flightTimeLine: action.data };
    case HasilPencarianActionType.GetSummaryTimeLimit:
      return { ...state, summaryTimeLimit: action.data };

    default:
      return { ...state };
  }
};

const HasilPencarianCommand = {
  getFlightTimeLineList: (payload: unknown): TAction<HasilPencarianAction, void> => {
    return (dispatch: TDispatch<HasilPencarianAction>) => {
      return APIDCN.post('/inventory/1.0/agent/search', payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: HasilPencarianActionType.GetFlightTimeLineList
            });
          }
        });
    };
  },
  getFlightTimeLineMultiCity: (payload: unknown): TAction<HasilPencarianAction, void> => {
    return (dispatch: TDispatch<HasilPencarianAction>) => {
      return APIDCN.post('/inventory/1.0/agent/search-multi-city', payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: HasilPencarianActionType.GetFlightTimeLineList
            });
          }
        });
    };
  },
  getSummaryTimeLimit: (params: string, token: string): TAction<HasilPencarianAction, void> => {
    return (dispatch: TDispatch<HasilPencarianAction>) => {
      return apiFetch(token).get(`/order/1.0/agent/timelimit-summary-product-id?productId=${params}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data.data,
              type: HasilPencarianActionType.GetSummaryTimeLimit
            });
          }
        });
    };
  },
  getSummaryTimeLimitbyOrderNumber: (params: string, token: string): TAction<HasilPencarianAction, void> => {
    return (dispatch: TDispatch<HasilPencarianAction>) => {
      return apiFetch(token).get(`/order/1.0/agent/timelimit-summary-order-number?number=${params}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data.data,
              type: HasilPencarianActionType.GetSummaryTimeLimit
            });
          }
        });
    };
  }
};

export { HasilPencarianCommand, HasilPencarianReducer, HasilPencarianDefault };
